.tableHead {
  background-color: black;
  z-index: 1300;
}

.tableHeadCell {
  color: #02134d;
  background-color: #e9eeff;
  font-size: 13px;
  padding: 2px;
  padding-left: 14px;
  font-weight: bold;
  z-index: 5;
  font-family: "Poppins", sans-serif;
}
.tablebody {
  font-size: 12px;
  color: rgb(12, 11, 11);
  padding: 12px;
  padding-left: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
